<template>
  <div class="general-report-by-flat" style="height: 100%">
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        v-model="setting_dialog"
        temporary
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
              <v-subheader class="pl-1">Додаткові налаштування</v-subheader>
              <v-row>
                <v-col cols="12">
                  <v-switch
                      v-model="charge_detalization"
                      inset
                      hide-details
                      color="success"
                      class="mt-2 ml-2"
                      :label="charge_detalization ? 'Деталізація оплати по послугах' : 'Загальна сума оплати'"
                  />
                </v-col>
                <v-col cols="12">
                  <Bank
                      :value="filters.bank_id"
                      @autocompleteChange="bankChangeHeader"
                  ></Bank>
                </v-col>
                <v-col cols="6">
                  <date-component
                      v-model="filters.pay_date_start"
                      label="Дата оплати з"
                      :placeholder="'Оберіть дату'"
                  />
                </v-col>
                <v-col cols="6">
                  <date-component
                      v-model="filters.pay_date_end"
                      label="Дата оплати по"
                      :placeholder="'Оберіть дату'"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      filled
                      v-model.number="filters.pay_sum_start"
                      v-decimal
                      label="Сума оплати з"
                      :placeholder="'Введіть суму'"
                      clearable
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      filled
                      v-model.number="filters.pay_sum_end"
                      v-decimal
                      label="Сума оплати по"
                      :placeholder="'Введіть суму'"
                      clearable
                  />
                </v-col>
              </v-row>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="pages" id="print_" style="overflow: auto;">
      <div class="page page-a4-auto non-printable" :style="`zoom: ${scale / 100}`" style="height: auto !important; min-height: 220mm">
        <div class="report" :style="`transform: scale(${zoom}); transform-origin: top left;`">
          <div class="report-section report-header" style="padding-top: 2px; flex-wrap: wrap;">
            <div class="rp-col-12 header-underline">
              <div class="rp-row rp-text-center" style="font-size: 1.1rem; font-weight: 500">
                {{ organization.full_name }} (Відомість оплати в розрізі дат та банків)
              </div>
            </div>
            <div class="rp-col-12"></div>
            <div class="rp-col-12 text-center mt-2">
              {{ print_data.organization_details_with_account }}
            </div>
            <div class="rp-col-12 text-left mt-8">
              Дата формування: {{ getCurrentDate() | formatDate }} {{ getPrintDateRange(date_start, date_end) }}
            </div>
          </div>
          <div class="empty-row report-header" style="height: 20px;"></div>
          <div class="table">
            <div class="table-row header" id="header">
              <div class="table-col first" style="flex: 0 0 200px">Дата оплати</div>
              <div class="table-col" style="flex: 0 0 90px">Особовий<br>рахунок</div>
              <div class="table-col" style="flex: 0 0 110px">Платник</div>
              <template v-if="charge_detalization">
                <div class="table-col"
                     v-for="(cols, idx) in services_columns"
                     :key="idx"
                     style="flex: 0 0 86px"
                >
                  Оплачено <br> {{ cols.name }}
                </div>
              </template>
              <div class="table-col" style="flex: 0 0 86px">Всього<br>оплачено</div>
              <div class="table-col" style="flex: 0 0 86px">Комісія</div>
            </div>
            <div class="table-row-group" v-for="(item, idx) in group_data" :key="idx" :id="`group-${idx}`" >
              <div class="group-header top" :class="{'splitter': item.splitter}">
                <div class="exp-icon" @click="toggleRow(`group-${idx}`, idx)">
                  <v-icon>{{ isExpanded(expanded, idx) ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                </div>
                <div class="table-col first" style="flex: 0 0 400px; justify-content: flex-start; padding-left: 32px">
                  {{ item.pay_date | formatDate }}
                </div>
                <template v-if="charge_detalization">
                  <div class="table-col"
                       v-for="(cols, inx) in services_columns"
                       :key="`group-row-${idx}-${inx}`"
                       style="flex: 0 0 86px"
                  >
                    {{ item[cols.value] | formatNumber }}
                  </div>
                </template>
                <div class="table-col" style="flex: 0 0 86px">
                  {{ item.sum | formatNumber }}
                </div>
                <div class="table-col" style="flex: 0 0 86px">
                  {{ item.commission | formatNumber }}
                </div>
              </div>
              <div class="group-content" style="height: auto" :class="{'visible': isExpanded(expanded, idx)}">
                <div class="table-row-group" v-for="(sitem, ipx) in item.items" :key="`${idx}-${ipx}`" :id="`group-${idx}-${ipx}`">
                  <div class="group-header" :class="{'splitter': sitem.splitter}">
                    <div class="exp-icon" style="padding-left: 20px" @click="toggleRow(`group-${idx}-${ipx}`, idx, ipx)">
                      <v-icon>{{ isExpanded(expanded, idx, ipx) ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                    </div>
                    <div class="table-col first" style="flex: 0 0 400px; justify-content: flex-start; padding-left: 60px">
                      {{ sitem.bank_name }}
                    </div>
                    <template v-if="charge_detalization">
                      <div class="table-col"
                           v-for="(cols, inx) in services_columns"
                           :key="`group-row-${idx}-${ipx}-${inx}`"
                           style="flex: 0 0 86px"
                      >
                        {{ sitem[cols.value] | formatNumber }}
                      </div>
                    </template>
                    <div class="table-col" style="flex: 0 0 86px">
                      {{ sitem.sum | formatNumber }}
                    </div>
                    <div class="table-col" style="flex: 0 0 86px">
                      {{ sitem.commission | formatNumber }}
                    </div>
                  </div>
                  <div class="group-content" :class="{'visible': isExpanded(expanded, idx, ipx)}">
                    <div class="table-row" v-for="(cont, inx) in sitem.items" :key="`${idx}-${ipx}-${inx}`" :class="{'splitter': cont.splitter}">
                      <div class="table-col first" style="flex: 0 0 200px">
                        {{ cont.address }}
                      </div>
                      <div class="table-col" style="flex: 0 0 90px">
                        {{ cont.person_id }}
                      </div>
                      <div class="table-col" style="flex: 0 0 110px">
                        {{ cont.payer }}
                      </div>
                      <template v-if="charge_detalization">
                        <div class="table-col"
                             v-for="(cols, inx) in services_columns"
                             :key="`row-${ipx}-${inx}`"
                             style="flex: 0 0 86px"
                        >
                          {{ cont[cols.value] | formatNumber }}
                        </div>
                      </template>
                      <div class="table-col" style="flex: 0 0 86px">
                        {{ cont.sum | formatNumber }}
                      </div>
                      <div class="table-col" style="flex: 0 0 86px">
                        {{ cont.commission | formatNumber }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-row footer">
              <div class="table-col first" style="flex: 0 0 400px; justify-content: flex-end">Підсумки:</div>
              <template v-if="charge_detalization">
                <div class="table-col"
                     v-for="(cols, idx) in services_columns"
                     :key="idx"
                     style="flex: 0 0 86px"
                >
                  {{ getTotal('report_data', cols.value) | formatNumber }}
                </div>
              </template>

              <div class="table-col" style="flex: 0 0 86px" id="first-col">
                {{ getTotal('report_data', 'sum') | formatNumber }}
              </div>
              <div class="table-col" style="flex: 0 0 86px">
                {{ getTotal('report_data', 'commission') | formatNumber }}
              </div>
            </div>
          </div>
          <div class="empty-row" style="height: 20px"></div>
        </div>
      </div>
      <div class="page page-a4-auto page-separator page-for-print"
           v-for="(page, index) in pages"
           :key="`page-${index}`"
           style="margin-bottom: 2px; visibility: hidden">
        <div class="page-number">
          {{ `Сторінка: ${page}/${pages}` }}
        </div>
        <div class="page-content" :style="`transform: scale(${zoom}); transform-origin: top left;`" style="height: 100%;">
          <div v-if="page === 1">
            <div class="header-underline" style="width: 100%">
              <div style="font-size: 1.1rem; font-weight: 500; width: 100%; text-align: center">
                {{ organization.full_name }} (Відомість оплати в розрізі дат та банків)
              </div>
            </div>
            <div class="mt-2" style="width: 100%; text-align: center; font-size: 12px">
              {{ print_data.organization_details_with_account }}
            </div>
            <div class="mt-8" style="text-align: left; font-size: 12px">
              Дата формування: {{ getCurrentDate() | formatDate }} {{ getPrintDateRange(date_start, date_end) }}
            </div>
          </div>
          <div class="empty-row" style="height: 20px;"></div>
          <div class="table">
            <div class="table-row header">
              <div class="table-col first" style="flex: 0 0 200px">Дата оплати</div>
              <div class="table-col" style="flex: 0 0 90px">Особовий<br>рахунок</div>
              <div class="table-col" style="flex: 0 0 110px">Платник</div>
              <template v-if="charge_detalization">
                <div class="table-col"
                     v-for="(cols, idx) in services_columns"
                     :key="idx"
                     style="flex: 0 0 86px"
                >
                  {{ cols.name }}
                </div>
              </template>
              <div class="table-col" style="flex: 0 0 86px">Всього<br>оплачено</div>
              <div class="table-col" style="flex: 0 0 86px">Комісія</div>
            </div>
            <div
                class="table-row"
                v-for="(row, row_idx) in pages_data[page]"
                :key="`table-data-${row_idx}`"
                :class="`level-${row.level}`"
            >
              <div class="table-col first"
                   :style="row.level === 2 ? 'flex: 0 0 200px' : 'flex: 0 0 400px'">
                <template v-if="row.level === 0" style="padding-left: 5px; text-align: left">
                  {{ row.pay_date | formatDate }}
                </template>
                <template v-if="row.level === 1">
                  {{ row.bank_name }}
                </template>
                <template v-if="row.level === 2">
                  {{ row.address }}
                </template>
              </div>
              <template v-if="row.level === 2">
                <div class="table-col" style="flex: 0 0 90px">
                  {{ row.person_id }}
                </div>
                <div class="table-col" style="flex: 0 0 110px">
                  {{ row.payer }}
                </div>
              </template>
              <template v-if="charge_detalization">
                <div class="table-col"
                     v-for="(cols, inx) in services_columns"
                     :key="`row-${row_idx}-${inx}`"
                     style="flex: 0 0 86px"
                >
                  {{ row[cols.value] | formatNumber }}
                </div>
              </template>
              <div class="table-col" style="flex: 0 0 86px">
                {{ row.sum | formatNumber }}
              </div>
              <div class="table-col" style="flex: 0 0 86px">
                {{ row.commission | formatNumber }}
              </div>
            </div>
            <div class="table-row footer" v-if="page === pages">
              <div class="table-col first" style="flex: 0 0 400px; justify-content: flex-end">Підсумки:</div>
              <template v-if="charge_detalization">
                <div class="table-col"
                     v-for="(cols, idx) in services_columns"
                     :key="idx"
                     style="flex: 0 0 86px"
                >
                  {{ getTotal('report_data', cols.value) | formatNumber }}
                </div>
              </template>
              <div class="table-col" style="flex: 0 0 86px">
                {{ getTotal('report_data', 'sum') | formatNumber }}
              </div>
              <div class="table-col" style="flex: 0 0 86px">
                {{ getTotal('report_data', 'commission') | formatNumber }}
              </div>
            </div>
          </div>
        </div>
        <div class="separator"></div>
      </div>
    </div>
  </div>
</template>

<script>

import {formatDate} from "@/filters";
import reportAPI from "@/utils/axios/reports"
import {ALERT_SHOW} from "@/store/actions/alert";
import {
  getCurrentDate,
  getPrintDateRange,
  group_by,
  isExpanded,
  LANDSCAPE_ZOOM_WIDTH,
  PORTRAIT_ZOOM_WIDTH
} from "@/utils/reports";

export default {
  name: "pays_by_date_by_flat",
  props: ['organization', 'print_data', 'global_settings', 'scale', 'date_start', 'date_end', 'generate',
    'open_all', 'generate_xlsx', 'generate_email', 'email_address'],
  components: {
    Bank: () => import("@/components/autocomplite/Bank")
  },
  computed: {
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '80%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '60%'
      }

      return '35%'
    }
  },
  data() {
    return {
      zoom: 1,
      pages: 0,
      pages_data: {},
      split_height: 0,
      row_height: 0,
      filters: {
        bank_id: null,
        pay_date_start: null,
        pay_date_end: null,
        pay_sum_start: null,
        pay_sum_end: null
      },
      expanded: [],
      zoom_width: PORTRAIT_ZOOM_WIDTH,
      charge_detalization: true,
      report_data: [],
      group_data: [],
      services_columns: {},
      setting_dialog: this.global_settings,
      current_date: this.getCurrentDate(),
      settings_watcher: null,
      generate_watcher: null,
      generate_xlsx_watcher: null,
      generate_email_watcher: null,
      open_all_watcher: null,
      local_response_people: this.print_data?.response_people ? this.print_data.response_people.slice() : [],
    }
  },
  methods: {
    onNavInput(e) {
      if (!e) {
        this.$emit('closeSettings')
      }
    },
    formatDate,
    getCurrentDate,
    getPrintDateRange,
    group_by,
    isExpanded,
    generate_xlsx_file() {
      if (this.date_start && this.date_end) {
        const payload = {date_start: this.date_start, date_end: this.date_end, filters: this.filters}
        reportAPI.pays_by_date_by_flat_xlsx(payload)
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = `pays_by_date_and_banks${formatDate(this.date_start, 'MM_YYYY')}_${formatDate(this.date_end, 'MM_YYYY')}.xlsx`
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_xlsx')
            })
      }
    },
    generate_email_send() {
      if (this.date_start && this.date_end) {
        this.$emit('email_loading_status', true)
        const payload = {date_start: this.date_start, date_end: this.date_end, filters: this.filters, email: this.email_address}
        reportAPI.pays_by_date_by_flat_email(payload)
            .then(response => response.data)
            .then(data => {
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {color: data.status === 'OK' ? 'success' : 'error', text: data.text})
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_email')
            })
      }
    },
    generate_report() {
      if (this.date_start && this.date_end) {
        const payload = {date_start: this.date_start, date_end: this.date_end, filters: this.filters}
        reportAPI.pays_by_date_by_flat(payload)
            .then(response => response.data)
            .then(data => {
              this.group_by_service(data)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_report', !!this.group_data)
              this.$nextTick(() => this.getOnFullPage())
            })
      }
    },
    getTotal(table_name, col, value = '') {
      if (!this[table_name]) return 0
      return this[table_name].reduce((sum, item) => sum + (value ? item[col][value] : item[col]), 0)
    },
    group_by_service(report_data) {
      this.services_columns = []
      let new_data = []
      let services = {}
      this.expanded = []

      report_data.cols.forEach(row => {
        const row_present_ = services[row.id] !== undefined
        if (!row_present_) {
          services[`service_${row.id}_sum`]=0

          this.services_columns.push({
            name: `${row.name}`,
            value: `service_${row.id}_sum`
          })
        }
      })

      report_data.rows.forEach(row => {
        const row_key = `${row.person_id}_${row.address}_${row.pay_date}`
        const row_present = new_data.find(item => item.row_key === row_key)
        const building_key = `${row.bank}_${row.pay_date}`

        if (!row_present) {
          const new_row = {
            row_key,
            address: row.address,
            building_key,
            splitter: false,
            flat_id: row.flat_id,
            month: row.month,
            person_id: row.person_id,
            bank: row.bank,
            bank_name: row.bank_name,
            pay_date: row.pay_date,
            payer: row.payer,
            sum: row.sum,
            commission: row.commission
          }

          Object.assign(new_row, JSON.parse(JSON.stringify(services)))

          new_row[`service_${row.service_id}_sum`] = (row.sum || 0)

          new_data.push(new_row)
        } else {
          row_present.sum += row.sum
          row_present.commission += row.commission
          row_present[`service_${row.service_id}_sum`] += (row.sum || 0)

        }
      })

      new_data = this.group_by(new_data, 'building_key', 'bank')
      const report_data_new = this.group_by(new_data, 'pay_date')
      const group_data = JSON.parse(JSON.stringify(report_data_new))
      this.report_data = JSON.parse(JSON.stringify(report_data_new))
      group_data.forEach((item, idx) => {
        this.expanded.push(`${idx}`)
        item.items.forEach(sub_item => {
          sub_item.items = []
        })
      })
      this.$set(this, 'group_data', group_data)
    },
    toggleRow(class_name, group_idx, item_idx) {
      const combinate_key = item_idx !== undefined ? `${group_idx}-${item_idx}` : `${group_idx}`

      if (this.expanded.includes(combinate_key)) {
        const idx = this.expanded.indexOf(combinate_key)
        if (idx >= 0) {
          this.expanded.splice(idx, 1)
          if (item_idx !== undefined) {
            this.group_data[group_idx].items[item_idx].items = []
          } else {
            this.group_data[group_idx].items = []
            this.expanded = this.expanded.filter(item => item.indexOf(`${group_idx}-`) === -1)
          }
        }
      } else {
        this.expanded.push(combinate_key)
        if (item_idx !== undefined) {
          if (this.group_data[group_idx].items[item_idx].items.length === 0) {
            this.group_data[group_idx].items[item_idx] = Object.assign(
                {},
                this.group_data[group_idx].items[item_idx],
                {items: this.copy_of_report_data(group_idx, item_idx)}
            )
          }
        } else {
          if (this.group_data[group_idx].items.length === 0) {
            this.group_data[group_idx] = Object.assign(
                {},
                this.group_data[group_idx],
                {items: this.copy_of_report_data(group_idx, item_idx)}
            )
          }
        }
      }
      this.setSplitter()
      this.$emit('clearOpenAll')
    },
    toggle_all() {
      if (!this.open_all) {
        this.expanded = []
        this.group_data.forEach(item => item.items = [])
      } else {
        this.group_data.forEach((group_1, group_idx) => {
          if (group_1.items.length === 0) {
            this.expanded.push(`${group_idx}`)
            Object.assign(
                group_1,
                {},
                {items: this.copy_of_report_data(group_idx, undefined)}
            )
            group_1.items.forEach((group_2, group_2_idx) => {
              this.expanded.push(`${group_idx}-${group_2_idx}`)
              if (group_2.items.length === 0) {
                Object.assign(
                    group_2,
                    {},
                    {items: this.copy_of_report_data(group_idx, group_2_idx)}
                )
              }
            })
          }
        })
      }
      this.setSplitter()
      this.$emit('clearOpenAll')
    },
    copy_of_report_data(group_idx, item_idx) {
      if (item_idx !== undefined) {
       return this.report_data[group_idx].items[item_idx].items.map(i => {
          return Object.assign({}, {...i})
        })
      } else {
        return this.report_data[group_idx].items.map(i => {
          return Object.assign({}, {...i})
        })
      }
    },
    getOnFullPage() {
      let width = 12
      const first_row = document.getElementById('header')
      const row = document.getElementById('first-col')
      first_row.childNodes.forEach(item => width += (item.clientWidth || 0))
      const gen_element = document.getElementsByClassName('report')[0]
      const scrollWidth = width * this.zoom
      const client_width = gen_element.clientWidth * this.zoom
      let zoom_width = 0
      let zoom = client_width / scrollWidth
      if (zoom === 0) zoom = 1
      if (zoom > 1) {
        zoom = 1
        zoom_width = LANDSCAPE_ZOOM_WIDTH
      }

      if (zoom !== 1) {
        zoom_width = 1 / zoom * LANDSCAPE_ZOOM_WIDTH
      }

      this.zoom = zoom
      this.zoom_width = zoom_width
      this.row_height = row.offsetHeight * this.zoom
      this.setSplitter()
    },
    setSplitter() {
      const hidden_page = document.getElementById('hidden-size-a4-portrait');
      // const page = document.getElementsByClassName('page')[0]
      const header = document.getElementById('header')
      const report_header = document.getElementsByClassName('report-header')
      const page_height = hidden_page.offsetHeight
      const headre_height = header.offsetHeight
      const end_height = 30
      let idx = 0

      let start_height = 0
      let all_height = 0
      let pages = 1
      let pages_data = []
      let pages_object = {}

      this.pages_data = {}

      report_header.forEach(item => {
        start_height += (item.offsetHeight || 0)
      })
      start_height += this.row_height

      this.group_data.forEach(gr_not_living => {
        idx += 1
        if (gr_not_living.splitter) gr_not_living.splitter = false
        if (pages === 1 && idx === 1) {
          all_height += start_height
        }
        if (pages > 1 && !pages_data.length) {
          all_height += headre_height
        }
        all_height += this.row_height

        gr_not_living.level = 0
        pages_data.push(gr_not_living)
        if ((Math.ceil(all_height) + this.row_height) >= page_height) {
          all_height = 0
          gr_not_living.splitter = true
          gr_not_living.page = pages
          pages_object[pages] = pages_data
          pages_data = []
          pages += 1
          all_height += headre_height
        }

        gr_not_living.items.forEach(gr_building => {
          idx += 1
          if (gr_building.splitter) gr_building.splitter = false
          all_height += this.row_height

          gr_building.level = 1
          pages_data.push(gr_building)
          if ((Math.ceil(all_height) + this.row_height) >= page_height) {
            all_height = 0
            gr_building.splitter = true
            gr_building.page = pages
            pages_object[pages] = pages_data
            pages_data = []
            pages += 1
            all_height += headre_height
          }

          gr_building.items.forEach(item => {
            idx += 1
            if (item.splitter) item.splitter = false
            all_height += this.row_height

            item.level = 2
            pages_data.push(item)
            if ((Math.ceil(all_height) + this.row_height) >= page_height) {
              all_height = 0
              item.splitter = true
              item.page = pages
              pages_object[pages] = pages_data
              pages_data = []
              pages += 1
              all_height += headre_height
            }
          })
        })
      })


      if (pages === 1) pages_object[pages] = pages_data
      if (!pages_object[pages]) pages_object[pages] = pages_data

      if (pages === 1) {
        if ((pages_object[pages].length * this.row_height + start_height + end_height) > page_height) {
          pages += 1
        }
      } else {
        if ((pages_object[pages].length * this.row_height + headre_height + end_height) > page_height) {
          pages += 1
        }
      }

      this.pages_data = pages_object
      this.pages = pages
    },
    closeSettings() {
      this.setting_dialog = false
      this.$emit('closeSettings')
    },
    watch_settings() {
      this.settings_watcher = this.$watch(
          'global_settings',
          {
            handler(payload) {
              this.setting_dialog = payload
            }
          }
      )
    },
    watch_generate() {
      this.generate_watcher = this.$watch(
          'generate',
          {
            handler(payload) {
              if (payload) {
                this.generate_report()
              }
            }
          }
      )
    },
    watch_generate_xlsx() {
      this.generate_xlsx_watcher = this.$watch(
          'generate_xlsx',
          {
            handler(payload) {
              if (payload) {
                this.generate_xlsx_file()
              }
            }
          }
      )
    },
    watch_open_all() {
      this.open_all_watcher = this.$watch(
          'open_all',
          {
            handler(payload) {
              if (payload !== undefined) {
                this.toggle_all()
              }
            }
          }
      )
    },
    bankChangeHeader(payload) {
      this.filters.bank_id = payload.value
    },
    watch_generate_email() {
      this.generate_email_watcher = this.$watch(
          'generate_email',
          {
            handler(payload) {
              if (payload) {
                this.generate_email_send()
              }
            }
          }
      )
    },
  },
  created() {
    this.watch_settings()
    this.watch_generate()
    this.watch_open_all()
    this.watch_generate_xlsx()
    this.watch_generate_email()
  },
  beforeDestroy() {
    if (this.settings_watcher) {
      this.settings_watcher()
    }
    if (this.generate_watcher) {
      this.generate_watcher()
    }
    if (this.open_all_watcher) {
      this.open_all_watcher()
    }
    if (this.generate_xlsx_watcher) {
      this.generate_xlsx_watcher()
    }
    if (this.generate_email_watcher) {
      this.generate_email_watcher()
    }
  }
}
</script>

<style lang="scss" scoped>
$border-color: #cbcbcb;

.page-a4-landscape-auto {
  width: 1160px !important;
  position: relative;
}
.page-a4-auto {
  position: relative;
}

.page-separator {
  page-break-after: always;
  page-break-inside: avoid;
  page-break-before: avoid;
}

.page-number {
  position: absolute;
  top: 3px;
  left: 6px;
  width: 130px;
  font-size: 10px;
  font-style: oblique;
  text-align: left;
}

.table {
  font-size: 11px;
  display: table;

  .table-row {
    display: flex;
  }

  .level-0 {
    .table-col:nth-child(1) {
      justify-content: flex-start;
    }
    .table-col {
      background-color: #e2e2e2;
    }
  }
  .level-1 {
    .table-col:nth-child(1) {
      padding-left: 20px;
      justify-content: flex-start;
    }
    .table-col {
      background-color: #f5f5f5;
    }
  }

  .table-row-group {
    .group-header {
      display: flex;
      position: relative;

      .exp-icon {
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
      }

      .table-col {
        background-color: #f5f5f5;
      }

      &.top {
        .table-col {
          background-color: #e2e2e2;
        }
      }
    }
    .group-content {
      display: none;
      &.visible {
        display: block;
      }
    }
  }
  .table-col {
    padding: 2px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid $border-color;
    border-top: 1px solid $border-color;
    word-break: break-word;

    &.first {
      border-left: 1px solid $border-color !important;
    }
  }
  .header {
    font-weight: 500;
    font-size: 10px !important;

    .table-col {
      border-bottom: none;
      background: #f3f3f3;
    }
  }
  .footer {
    font-weight: 500;
    .table-col {
      border-bottom: 1px solid $border-color;
      background-color: #e2e2e2;
  }
  }
}

@media print {
  * {
    float: none !important;
    clear: both;
    //height: auto !important;
  }

  .pages {
    height: auto !important;
    overflow: visible !important;
  }

  .page {
    visibility: visible !important;
    margin: 0 2px !important;
  }

  @page {
    margin: 0 0 5mm 0 !important;
  }
}

.show-row {
  display: flex !important;
}

.hide-row {
  display: none !important;
}

.general-report-by-flat {
  .report-table {
    width: 100%;
    text-align: center;

    .report-table-row:nth-child(1) {
      & > div {
        background-color: #f9f9f9;
        font-weight: 500;
      }
    }

    .report-table-group-header {
      position: relative;

      & > div {
        background-color: #fafafa;
        font-weight: 500;
      }
    }

    .report-table-sub-group-header {
      position: relative;

      & > div {
        background-color: #fafafa5c;
        font-weight: 500;
      }
    }

    .report-table-row {
      min-height: 26px;
      line-height: 1.3;

      &:nth-child(1) {
        .report-table-col-group {
          .col-group {
            .col-group-row:nth-child(1) {
              border-bottom: 1px solid #dde1e3;
            }
          }
        }
      }

      .report-table-col:last-child:not(.report-table-col-group) {
        border-right: 1px solid #dde1e3;
      }

      .report-table-col-group:last-child {
        .col-group-col:last-child {
          border-right: 1px solid #dde1e3;
        }

        .col-group-col:not(:last-child) {
          border-right: 1px solid #dde1e3;
        }
      }

      .report-table-col-group:not(:last-child) {
        .col-group-col:not(:last-child) {
          border-right: 1px solid #dde1e3;
        }
      }

      &:last-child {
        .report-table-col:not(.report-table-col-group) {
          border-bottom: 1px solid #dde1e3;
        }

        .report-table-col-group {
          border-bottom: 1px solid #dde1e3;
        }
      }
    }

  }

  .report-table-col-group {
    //.col-group {
    //  .col-group-row:nth-child(1) {
    //    border-right: 1px solid #dde1e3;
    //  }
    //}
    .col-group-row {
      flex: 1;
    }

    //&:nth-last-child(1) {
    //  .col-group-col:last-child {
    //    border-right: 1px solid #dde1e3;
    //  }
    //}
  }
  .col-group-col {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .report-table-col, .report-table-col-group {
    border-left: 1px solid #dde1e3;
    border-top: 1px solid #dde1e3;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      padding: 3px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .report-table-group {
    font-size: 12px;
    width: 100%;

    .report-table-group-header {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      font-size: 12px;
      font-weight: 500;
      background-color: #f2f2f2;

    }

    .report-table-group-content {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      font-size: 12px;

      .report-table-col {
        &:nth-child(1) {
          padding-left: 30px !important;
          padding-right: 5px;
        }
      }
    }
  }

  .col-group {
    display: flex;
    flex-direction: column;
    width: 100%;

    .col-group-row {
      display: flex;

      &:nth-child(1) {
        //border-bottom: 1px solid $border-color;
        text-align: center;
        justify-content: center;
      }

      .report-table-col {
        //border-right: 1px solid $border-color;

        &:last-child {
          border-right: none;
        }
      }
    }
  }

}
</style>